.fun-modal-container {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 10001;
}

.fun-modal-container .fun-modal {
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  max-height: 80%;
  background-color: #fff;
  z-index: 10001;
  transition: all 250ms ease-out;
  margin-bottom: -1.2rem;
}

.fun-modal-container .fun-modal .fun-modal-header {
  width: 100%;
  color: #949aa7;
  padding: 0.2667rem 0;
  position: relative;
  text-align: center;
  line-height: 0.6667rem;
  height: 0.6667rem;
  box-sizing: content-box;
  background-color: #f8f8f8;
  margin-top: -1.2rem;
}

.fun-modal-container .fun-modal .fun-modal-header .left,
.fun-modal-container .fun-modal .fun-modal-header .right {
  text-align: left;
  padding-left: 0.2667rem;
  height: 1.2rem;
  line-height: 1.2rem;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.fun-modal-container .fun-modal .fun-modal-header .icon-con.right {
  right: 0;
  left: auto;
  padding-left: 0;
  padding-right: 0.2667rem;
  text-align: right;
  color: #cbb486;
}

.fun-modal-container .fun-modal .fun-modal-header .fun-icon {
  font-size: 0.5333rem;
  line-height: inherit;
}

.fun-modal-container .fun-modal .fun-modal-header .fun-modal-title {
  flex: 1;
  text-align: center;
  font-size: 0.4267rem;
}

.fun-modal-container .fun-modal .fun-modal-wrapper {
  display: block;
  height: 100%;
}

.fun-modal-container .fd-back {
  background-color: rgba(0,0,0,.5);
  transition: opacity 250ms linear;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.fun-modal-container .fun-modal {
  transform: translateY(100%);
  margin-bottom: -1.2rem;
}

.fun-modal-container.fun-modal-container-enter {
  display: block;
}

.fun-modal-container.fun-modal-container-enter .fun-modal {
  transform: translateY(100%);
  margin-bottom: -1.2rem;
}

.fun-modal-container.fun-modal-container-enter .fd-back {
  opacity: 0;
}

.fun-modal-container.fun-modal-container-active {
  display: block;
}

.fun-modal-container.fun-modal-container-active .fun-modal {
  transform: translateY(0%);
  margin-bottom: 0;
}

.fun-modal-container.fun-modal-container-active .fd-back {
  opacity: 1;
}

.fun-modal-container.fun-modal-container-enter-done {
  display: block;
}

.fun-modal-container.fun-modal-container-enter-done .fun-modal {
  transform: translateY(0%);
  margin-bottom: 0;
}

.fun-modal-container.fun-modal-container-enter-done .fd-back {
  opacity: 1;
}

.fun-modal-container.fun-modal-container-exit {
  display: block;
}

.fun-modal-container.fun-modal-container-exit .fun-modal {
  transform: translateY(0%);
  margin-bottom: 0;
}

.fun-modal-container.fun-modal-container-exit .fd-back {
  opacity: 1;
}

.fun-modal-container.fun-modal-container-exit-active {
  display: block;
}

.fun-modal-container.fun-modal-container-exit-active .fun-modal {
  transform: translateY(100%);
  margin-bottom: -1.2rem;
}

.fun-modal-container.fun-modal-container-exit-active .fd-back {
  opacity: 0;
}

.fun-modal-container.fun-modal-container-exit-done {
  display: none;
}