@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?ru7diw');
  src: url('fonts/icomoon.eot?ru7diw#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?ru7diw') format('truetype'),
    url('fonts/icomoon.woff?ru7diw') format('woff'),
    url('fonts/icomoon.svg?ru7diw#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 0.4267rem;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icon-activity:before {
  content: "\e900";
}

.icon-icon-add:before {
  content: "\e901";
}

.icon-icon-analyze-policy:before {
  content: "\e902";
}

.icon-icon-angle-left:before {
  content: "\e903";
}

.icon-icon-arrow-down-filling:before {
  content: "\e904";
}

.icon-icon-arrow-down:before {
  content: "\e905";
}

.icon-icon-arrow-right:before {
  content: "\e906";
}

.icon-icon-arrow-up:before {
  content: "\e907";
}

.icon-icon-back-policy:before {
  content: "\e908";
}

.icon-icon-banking-service:before {
  content: "\e909";
}

.icon-icon-business-registration:before {
  content: "\e90a";
}

.icon-icon-checked-solid:before {
  content: "\e90b";
}

.icon-icon-checked:before {
  content: "\e90c";
}

.icon-icon-circles:before {
  content: "\e90d";
}

.icon-icon-clock:before {
  content: "\e90e";
}

.icon-icon-close:before {
  content: "\e90f";
}

.icon-icon-collect:before {
  content: "\e910";
}

.icon-icon-unCollect:before {
  content: "\e911";
}

.icon-icon-company-info:before {
  content: "\e912";
}

.icon-icon-company:before {
  content: "\e913";
}

.icon-icon-consultation:before {
  content: "\e914";
}

.icon-icon-creativity:before {
  content: "\e915";
}

.icon-icon-date:before {
  content: "\e916";
}

.icon-icon-deal-with:before {
  content: "\e917";
}

.icon-icon-edit:before {
  content: "\e918";
}

.icon-icon-email:before {
  content: "\e919";
}

.icon-icon-expert-reservation:before {
  content: "\e91a";
}

.icon-icon-finance-industry:before {
  content: "\e91b";
}

.icon-icon-finance-taxation:before {
  content: "\e91c";
}

.icon-icon-financial:before {
  content: "\e91d";
}

.icon-icon-health-industry:before {
  content: "\e91e";
}

.icon-icon-health:before {
  content: "\e91f";
}

.icon-icon-show:before {
  content: "\e920";
}

.icon-icon-hide:before {
  content: "\e921";
}

.icon-icon-home-no-fill:before {
  content: "\e922";
}

.icon-icon-home:before {
  content: "\e923";
}

.icon-icon-intellectual-property:before {
  content: "\e924";
}

.icon-icon-Intelligent-learning:before {
  content: "\e925";
}

.icon-icon-knowledge-service:before {
  content: "\e926";
  color: #333303;
}

.icon-icon-legal-service:before {
  content: "\e927";
}

.icon-icon-location-no-fill:before {
  content: "\e928";
}

.icon-icon-location:before {
  content: "\e929";
}

.icon-icon-lock:before {
  content: "\e92a";
}

.icon-icon-logout:before {
  content: "\e92b";
}

.icon-icon-mail:before {
  content: "\e92c";
}

.icon-icon-mall-industry:before {
  content: "\e92d";
}

.icon-icon-marketing:before {
  content: "\e92e";
}

.icon-icon-mine:before {
  content: "\e92f";
}

.icon-icon-user:before {
  content: "\e930";
}

.icon-icon-mobile:before {
  content: "\e931";
}

.icon-icon-notification:before {
  content: "\e932";
}

.icon-icon-other-industry:before {
  content: "\e933";
}

.icon-icon-other-service:before {
  content: "\e934";
}

.icon-icon-phone:before {
  content: "\e935";
}

.icon-icon-policy-apply-end:before {
  content: "\e936";
}

.icon-icon-policy-applying:before {
  content: "\e937";
}

.icon-icon-ring:before {
  content: "\e938";
}

.icon-icon-search:before {
  content: "\e939";
}

.icon-icon-select:before {
  content: "\e93a";
}

.icon-icon-task:before {
  content: "\e93b";
}

.icon-icon-technology-industry:before {
  content: "\e93c";
}

.icon-icon-travel-industry:before {
  content: "\e93d";
}

.icon-icon-point-change:before {
  content: "\e93e";
}

.icon-icon-vip-label:before {
  content: "\e93f";
}

.icon-icon-point:before {
  content: "\e940";
}

.icon-icon-policy:before {
  content: "\e941";
}

.icon-icon-policy-label:before {
  content: "\e942";
}

.icon-icon-message-tip:before {
  content: "\e943";
}

.icon-icon-upload:before {
  content: "\e944";
}