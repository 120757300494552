.zby-picker-column {
  flex: 1;
}

.zby-picker-column-list {
  position: relative;
  height: 5.6rem;
  overflow: hidden;
}

.zby-picker-column-list .zby-picker-column-window {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  background-position: top,bottom;
  background-size: 100% 2.2133rem;
  background-repeat: no-repeat;
  z-index: 3;
}

.zby-picker-column-list .zby-picker-column-indicator {
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 1.12rem;
  transform: translate3D(0, -50%, 0);
  z-index: 3;
  margin: 0 0.2667rem;
  background: rgba(255,208,163,.3);
  border-radius: 0.08rem;
}

.zby-picker-column-list .zby-picker-column-top {
  top: -100%;
}

.zby-picker-column-list .zby-picker-column-content {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  padding: 2.2133rem 0;
  transform: translate3D(0, 0, 0);
  transition-timing-function: linear;
  transition-duration: 0ms;
  transition-property: transform;
}

.zby-picker-column-list .zby-picker-column-content .zby-picker-column-col {
  height: 1.12rem;
  padding: 0 0.1333rem;
  font-size: 0.4rem;
  text-align: center;
  line-height: 1.12rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: font-size 300ms ease-in;
  color: #ffd0a3;
}

.zby-picker-column-list .zby-picker-column-content .zby-picker-column-col.selected {
  font-size: 0.48rem;
  color: #ffd0a3;
  background: rgba(0,0,0,0);
}