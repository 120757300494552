.audio-player-container {
  position: absolute;
  top: 0.8rem;
  right: 0.4rem;
  z-index: 99;
}

.audio-player-container .music-play-img {
  width: 1.0667rem;
  height: 1.0667rem;
  animation: rotate 5s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}