.login-container {
  display: flex;
  flex-direction: column;
  margin: 0 0.4rem;
  padding-top: 0.8933rem;
  height: 100vh;
}

.login-container .login-content-container {
  display: flex;
  flex-direction: column;
}

.login-container .login-content-container .login-item-container,
.login-container .login-content-container .login-btn {
  margin-top: 0.8933rem;
}

.login-container .login-content-container .login-forget-pwd {
  width: 3.2rem;
  color: #383838;
  font-size: 0.3467rem;
  line-height: 0.208rem;
  padding: 0.8933rem 0.1333rem 0.1333rem 0;
}