.cold-zby-picker-container {
  display: flex;
  align-items: center;
}

.cold-zby-picker .cold-zby-picker-popup-mask {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0,0,0,.4);
  transition: all .3s;
}

.cold-zby-picker .cold-zby-picker-popup-mask.hide {
  z-index: -1;
  background-color: rgba(0,0,0,0);
}

.cold-zby-picker .cold-zby-picker-popup-wrap {
  position: fixed;
  left: 0;
  right: 0;
  bottom: -100%;
  z-index: 1000;
  background-color: #fff;
  transition: all .3s;
}

.cold-zby-picker .cold-zby-picker-popup-wrap.popup {
  bottom: 0;
}

.cold-zby-picker .cold-zby-picker-popup-wrap .cold-zby-picker-popup-header {
  display: flex;
  border-bottom: 0.0133rem solid #ddd;
}

.cold-zby-picker .cold-zby-picker-popup-wrap .cold-zby-picker-popup-header .cold-zby-picker-popup-item {
  padding: 0.1333rem 0.4rem;
  height: 0.8rem;
  font-size: 0.4533rem;
  line-height: 0.8rem;
  color: #000;
  text-align: center;
}

.cold-zby-picker .cold-zby-picker-popup-wrap .cold-zby-picker-popup-header .cold-zby-picker-popup-item.cold-zby-picker-header-title {
  flex: 1;
}

.cold-zby-picker .cold-zby-picker-popup-wrap .cold-zby-picker-popup-header .cold-zby-picker-popup-item.cold-zby-picker-header-left,
.cold-zby-picker .cold-zby-picker-popup-wrap .cold-zby-picker-popup-header .cold-zby-picker-popup-item.cold-zby-picker-header-right {
  color: #00b4a5;
}