.generate-item-container {
  display: flex;
  flex-direction: row;
  min-height: 1.6rem;
  font-size: 0.4rem;
  font-weight: 700;
}

.generate-item-container .circle {
  margin: 0 0.2rem;
  width: 0.3733rem;
  height: 0.3733rem;
  border: 0.0667rem solid #ffb773;
  border-radius: 100%;
}

.generate-item-container .solid-circle {
  background: #ffb773;
}

.generate-item-container .line {
  width: 0.1067rem;
  height: calc(100% - 0.3467rem);
  background: #ffb773;
  margin: 0 0.32rem;
}

.generate-item-container .age {
  line-height: 0.4rem;
  color: #ffb773;
}

.generate-item-container .experience {
  font-size: 0.3733rem;
  font-weight: 400;
  color: #ffd0a3;
  margin-bottom: 0.5333rem;
  margin-top: 0.0667rem;
  padding: 0 !important;
}