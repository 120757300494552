.main-container[_fun-layout].static {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.main-container[_fun-layout].fixed {
  position: static !important;
}

.main-container[_fun-layout].fixed ._top-part {
  top: 0;
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 100;
}

.main-container[_fun-layout].fixed ._bottom-part {
  position: fixed;
  bottom: 0 !important;
  top: auto;
  left: 0;
  width: 100%;
  z-index: 100;
}

.main-container[_fun-layout].static ._main-tr[_fun-layout] {
  height: 100%;
  width: 100%;
  background: #fff;
}

.main-container[_fun-layout].static .table[_fun-layout] {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
}

.main-container[_fun-layout].static .tr[_fun-layout] {
  display: table-row;
}

.main-container[_fun-layout].static .main-content[_fun-layout] {
  width: 100%;
  height: 100%;
  position: relative;
}