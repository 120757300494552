.forget-pwd-container {
  display: flex;
  flex-direction: column;
  margin: 0 0.4rem;
  padding-top: 0.5333rem;
  height: 100vh;
}

.forget-pwd-container .forget-pwd-content-container {
  display: flex;
  flex-direction: column;
}

.forget-pwd-container .forget-pwd-content-container .forget-pwd-item-container,
.forget-pwd-container .forget-pwd-content-container .forget-pwd-btn {
  margin-top: 0.8933rem;
}

.forget-pwd-container .forget-pwd-content-container .forget-pwd-otp-container {
  position: relative;
}

.forget-pwd-container .forget-pwd-content-container .forget-pwd-otp-container .forget-pwd-otp-item-container {
  margin-top: 0.8933rem;
}

.forget-pwd-container .forget-pwd-content-container .forget-pwd-otp-container .forget-pwd-send-otp {
  position: absolute;
  top: 0.8933rem;
  right: 0;
  bottom: 0;
}