.fun-end-tips {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0.4267rem;
  padding: 0.5333rem 0;
  font-size: 0.32rem;
  color: #949aa7;
}

.fun-end-tips .tips-line {
  display: inline-block;
  width: 1.2rem;
  height: 0.0133rem;
  margin: 0 0.2667rem;
  background-image: linear-gradient(to bottom, transparent 50%, #EAEAEA 50%, #EAEAEA 100%);
}