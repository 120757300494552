.fun-image {
  position: relative;
}

.fun-image .scale-img {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

.fun-image .origin-img {
  width: 100%;
  vertical-align: top;
}

.fun-image .loading-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.4rem;
  color: #dfdfdf;
  background: #f2f2f2;
}