.main-container[_fun-inner-scroll] {
  overflow: hidden;
  height: 100%;
}

.main-container[_fun-inner-scroll] .scroll-wrapper[_fun-inner-scroll] {
  overflow-y: auto;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  transform: translate3d(0, 0, 0);
  transition-timing-function: cubic-bezier(0.1, 0.57, 0.1, 1);
  transition-duration: 300ms;
  transition-property: transform;
}

.main-container[_fun-inner-scroll] .scroll-wrapper[_fun-inner-scroll] .scroll-content[_fun-inner-scroll] {
  min-height: 101%;
}