.fun-pull-to-refresh .pull-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.4133rem;
}

.fun-pull-to-refresh .pull-icon {
  width: 0.6133rem;
  height: 0.6133rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.fun-pull-to-refresh .deactivate-icon {
  background-image: url("data:img/jpg;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAuCAMAAABgZ9sFAAABAlBMVEUAAAA1NTVAQEA0NDQ/Pz8/Pz9KSkpOTk4vLy8/Pz9LS0szMzM/Pz9KSkpKSko0NDQ/Pz9LS0s0NDQ+Pj5OTk4uLi5PT08uLi5PT08vLy9NTU1FRUU4ODgwMDBHR0dOTk5EREQ5OTkvLy9OTk4vLy80NDQxMTFPT09CQkI7OzsuLi44ODgvLy9OTk5ERERPT08tLS1DQ0M3Nzc0NDRHR0c5OTn/1V47OztJSUlMTExAQEAxMTFFRUU+Pj4vLy89PT2gi0+diE1OTk7Ep1RGRUI/PjvApFRJSEXKrFXGqVT0zVy8oVFEQz89PDn2zlzsx1vkwFm0mlGkjk+XhEtYU0KpklAu6pkNAAAAMXRSTlMABxDc+Ovc29vZzMy0l3JbT0lJIRcX+Pjr6+Xl5eXcuLi4uLSzl5eLi4uLcnJbW09PsZU99gAAAg5JREFUSMeVlmlX6jAQhgOo4O6Fu2/e675rKU1LKxWlBdnc/f9/xYS0TtLXcjSfgPPwMOdlMlOWPcW1vaX52ZmZ2fml/bUim3oKJ/8sy/L9Vqt14bqu4/5fLeTDK78EnOKCdxyn/vsw5wuf/p6fa7grjsDr9VL1LfVyo6Fw37DLsw0/8HmhoeG+sie4bS9+MenanJfiVDvhdqlmuOc8T9KpvmXaJa/5CwueZ9j9rN0OFqn+5SbhYFd8EOy8JtgEHOyCryallAF/urt7UrmTPaioclaaCU5BXnL+qOWu7OHRRP4T8Tbnl2YygcD/SP1xM4NbCr8APFwV+HqCU+559nBD9PfV++1BkZ3m4UbuCg/P2O777PYEP2CbeTjkLvgtVgbcJzvhyl5h3xHPTSb8wb4S3r7p9rK1j4eDR8K/6fiA8+uemUzc53yg4Xox91zxZI874qN7vZgy4V5X8hHZJ/TQodwrRpA9yfej1B7Lt11Hy33L/Jt6HcVLPKHHeu4HZhMovjOSeDyUL8dG7mfZFlP8A+dtovUWY+sGbkWSvxHxybJiaGC6HoJOeXWuY7weePmifkKP4PLB1Va8orP9fpQzOKKH29vnUbbfK4VpYwn6vQpDD+c79fsOjFSY7zBSaWDjjMSBTetg+nwv1WDZ4HzHZYOrDO24ynBRkr1Unb6GKXdcw7jkKXdc8h98hHgB6Cli2vPfXRgAAAAASUVORK5CYII=");
}

.fun-pull-to-refresh .activate-icon {
  background-image: url("data:img/jpg;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAuCAMAAABgZ9sFAAAAZlBMVEUAAADu0oDu0X/uzIju0oHu0YDt0YDt0YDu0oHs0YDt0YDt0n/u0oHu0n/x0oH004Xvz4D/34Dw0YPt0YB5VgCzlD/pzXzrz32dfCeaeSSgfyp/XAa1lkKHZQ+vjzqXdiHdwG2mhjFjGmh8AAAAE3RSTlMA3LYH+Ovl2cyXi3JbT0kXEAghc0+faQAAAUJJREFUSMeV1umugjAQBeCxbLIUioOgbFff/yVvIWApUwhz4p8mn3qi6Qywj5KJiALfDyKRSAWn8TKBVkTmHeIyDZEkTEu3zm/ozC139YjxMDFpVNzxJPfC1g+7CC30sD6baOKLTW/ahPYx/WO8kHjVOV5Kvvw7pHg9DsNYk/rlzFOi/yqdN/HppL3QoZ0+9DTPqF7y2vtMc7HTbw07/Zp8g1YEgHLo9llV48vhFUiHbjR/1g4vIdkem4VMXL+V+ASEQ8/891VW+YjqlVMfQWAO3+XHXvjqv0YE4JtDN+sfX31nhL/lfddqbfjs267f8gBJVk4TQMThEQgOF5BweAKSwyUoDlcA4joX5Hqc8sy+fJTTywcpkvRV1SNJejA48DMMH6SDgzeWeEOPOVJ5A5u3DnjLhrfK2IuSv4b5S575CPEPRxd8/reFqFwAAAAASUVORK5CYII=");
}