.fun-common-input {
  display: flex;
  align-items: center;
  border: 0.0256rem solid #c9c9c9;
  border-radius: 0.2rem;
  min-height: 1.2rem;
  padding-left: 0.3333rem;
}

.fun-common-input .input-inner-con {
  flex: 1;
}

.fun-common-input .input-inner-con input {
  display: block;
  color: #000;
  font-size: 0.3733rem;
  line-height: 0.32rem;
  border: none;
  width: 100%;
  font-weight: 500;
  background: rgba(0,0,0,0) !important;
  font-family: HarmonyOSSansSC-Bold;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-text-fill-color: #000 !important;
  color: #000 !important;
  transition: background-color 5000000000s ease-in-out 0s;
}

.fun-common-input .input-inner-con input::placeholder,
.fun-common-input .input-inner-con textarea::placeholder {
  -webkit-text-fill-color: #383838 !important;
  font-family: HarmonyOSSansSC-Regular;
  font-weight: 400;
  color: #383838;
  font-size: 0.3733rem;
}

.fun-common-input .input-inner-con input.input:disabled,
.fun-common-input .input-inner-con textarea.textarea:disabled {
  background-color: rgba(0,0,0,0) !important;
  color: #949aa7 !important;
  -webkit-text-fill-color: #949aa7 !important;
  opacity: 1;
  transition: none !important;
}

.fun-common-input .input-inner-con textarea {
  width: 100%;
  resize: none;
  font-size: 0.3733rem;
  color: #000 !important;
  font-family: HarmonyOSSansSC-Bold;
  font-weight: 500;
  border: 0.0133rem solid #c9c9c9;
  border-radius: 0.2133rem;
}

.fun-common-input .input-inner-con textarea::placeholder {
  color: #383838;
  font-family: HarmonyOSSansSC-Regular;
  font-weight: 400;
}

.fun-common-input .required {
  color: red;
  font-size: 0.4rem;
  font-family: HarmonyOSSansSC-Bold;
  font-weight: bold;
}

.fun-common-input .left-con {
  overflow: hidden;
  color: #383838;
  text-align: center;
  padding-right: 0.1333rem;
}

.fun-common-input .right-con {
  transition: all 100ms ease-in;
  overflow: hidden;
  color: #383838;
  text-align: center;
  padding: 0.3333rem;
  height: 100%;
  display: flex;
  align-items: center;
}