.spinner-container {
  min-width: 2.6667rem;
  padding: 0.2667rem;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  border-radius: 0.8rem;
  background-color: rgba(0,0,0,.8);
  z-index: 10000;
}

.loading-txt {
  color: #fff;
  margin-right: 0.1333rem;
  font-size: 0.4267rem;
}

.la-ball-newton-cradle,
.la-ball-newton-cradle>div {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.la-ball-newton-cradle {
  display: block;
  font-size: 0;
  color: #fff;
}

.la-ball-newton-cradle.la-dark {
  color: #333;
}

.la-ball-newton-cradle>div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}

.la-ball-newton-cradle {
  width: 0.5333rem;
  height: 0.1333rem;
}

.la-ball-newton-cradle>div {
  width: 0.1333rem;
  height: 0.1333rem;
  border-radius: 100%;
}

.la-ball-newton-cradle>div:first-child {
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  transform: translateX(0%);
  -webkit-animation: ball-newton-cradle-left 1s 0s ease-out infinite;
  -moz-animation: ball-newton-cradle-left 1s 0s ease-out infinite;
  -o-animation: ball-newton-cradle-left 1s 0s ease-out infinite;
  animation: ball-newton-cradle-left 1s 0s ease-out infinite;
}

.la-ball-newton-cradle>div:last-child {
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
  transform: translateX(0%);
  -webkit-animation: ball-newton-cradle-right 1s 0s ease-out infinite;
  -moz-animation: ball-newton-cradle-right 1s 0s ease-out infinite;
  -o-animation: ball-newton-cradle-right 1s 0s ease-out infinite;
  animation: ball-newton-cradle-right 1s 0s ease-out infinite;
}

.la-ball-newton-cradle.la-sm {
  width: 0.2667rem;
  height: 0.0533rem;
}

.la-ball-newton-cradle.la-sm>div {
  width: 0.0533rem;
  height: 0.0533rem;
}

.la-ball-newton-cradle.la-2x {
  width: 1.0667rem;
  height: 0.2667rem;
}

.la-ball-newton-cradle.la-2x>div {
  width: 0.2667rem;
  height: 0.2667rem;
}

.la-ball-newton-cradle.la-3x {
  width: 1.6rem;
  height: 0.4rem;
}

.la-ball-newton-cradle.la-3x>div {
  width: 0.4rem;
  height: 0.4rem;
}

@-webkit-keyframes ball-newton-cradle-left {
  25% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  50% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@-moz-keyframes ball-newton-cradle-left {
  25% {
    -moz-transform: translateX(-100%);
    transform: translateX(-100%);
    -moz-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  50% {
    -moz-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@-o-keyframes ball-newton-cradle-left {
  25% {
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  50% {
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@keyframes ball-newton-cradle-left {
  25% {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  50% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@-webkit-keyframes ball-newton-cradle-right {
  50% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }

  75% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@-moz-keyframes ball-newton-cradle-right {
  50% {
    -moz-transform: translateX(0%);
    transform: translateX(0%);
  }

  75% {
    -moz-transform: translateX(100%);
    transform: translateX(100%);
    -moz-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  100% {
    -moz-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@-o-keyframes ball-newton-cradle-right {
  50% {
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }

  75% {
    -o-transform: translateX(100%);
    transform: translateX(100%);
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  100% {
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@keyframes ball-newton-cradle-right {
  50% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }

  75% {
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  100% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}