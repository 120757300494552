.swiper-item-container {
  display: flex;
  align-items: center;
  height: 5.0667rem;
  position: relative;
  margin: 0 0.6667rem;
}

.swiper-item-container .bg-img {
  width: 100%;
  height: 5.0667rem;
  position: absolute;
}

.swiper-item-container .inner-img {
  flex: 1;
  padding: 0.3733rem 0.5333rem;
}