.people-generate-container {
  width: 100%;
  height: 100%;
}

.people-generate-container .content-container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.people-generate-container .content-container .bg-content {
  position: absolute;
  z-index: 2;
  font-size: 0.32rem;
  width: 100%;
  text-align: center;
  color: #ffd0a3;
  top: 0.1333rem;
  font-weight: 400;
}

.people-generate-container .content-container .bg-img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.people-generate-container .content-container .question-container {
  margin-left: 0.8rem;
  margin-right: 0.8rem;
  z-index: 1;
  margin-top: 7.6rem;
}

.people-generate-container .content-container .question-container .question {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  height: 1.1733rem;
  border: 0.0267rem solid #ffa957;
  margin-bottom: 0.5333rem;
  padding: 0 0.3467rem;
}

.people-generate-container .content-container .question-container .question .content {
  font-size: 0.48rem;
  font-weight: 400;
  line-height: 0.64rem;
  color: #ffb773;
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.people-generate-container .content-container .question-container .question .arrow {
  width: 0.4267rem;
  height: 0.48rem;
  padding-bottom: 0.24rem;
}

.people-generate-container .content-container .question-container .generate-img {
  width: 100%;
  height: 1.76rem;
}