._fun-toast[_fun-toast] {
  display: block;
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 100000;
  transition: all 300ms cubic-bezier(0, 1.27, 0.75, 1.02);
  pointer-events: none;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
}

._fun-toast[_fun-toast] ._fun-toast-content {
  display: inline-block;
  flex-direction: column;
  max-width: 80%;
  border-radius: 0.8rem;
  background-color: rgba(0,0,0,.8);
  color: #fff;
  padding: 0.2667rem 0.4533rem;
  font-size: 0.4267rem;
}

._fun-toast[_fun-toast]._fun-toast-enter {
  opacity: 0;
  top: 60%;
}

._fun-toast[_fun-toast]._fun-toast-active {
  opacity: 1;
  top: 50%;
}

._fun-toast[_fun-toast]._fun-toast-exit {
  opacity: 1;
  top: 50%;
}

._fun-toast[_fun-toast]._fun-toast-exit-active {
  opacity: 0;
  top: 60%;
}