.fun-swiper {
  width: 100%;
}

.fun-swiper .swiper-container1 {
  width: 100%;
}

.fun-swiper .swiper-slide {
  background-position: center;
  background-size: cover;
}

.fun-swiper .swiper-zoom-container {
  width: 100%;
  height: auto;
}

.fun-swiper .swiper-pagination-bullet {
  width: 0.1867rem;
  height: 0.1867rem;
  background-color: #fff;
}

.fun-swiper .swiper-pagination-bullet-active {
  width: 0.1867rem;
  height: 0.1867rem;
  background-color: #cbb486;
}