.main-container[_fun-scroll] {
  background-color: #f4f4f4;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  overflow: hidden;
}

.scroll-wrapper[_fun-scroll] {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transform: translate3d(0, 0, 0);
  transition-timing-function: cubic-bezier(0.1, 0.57, 0.1, 1);
  transition-duration: 300ms;
  transition-property: transform;
}

.scroll-wrapper[_fun-scroll] .scroll-content {
  min-height: 99%;
}

.scroll-back[_fun-scroll] {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
}

#console_test_id {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1000000;
  background-color: #000;
  color: #eee;
  line-height: 0.8rem;
  white-space: normal;
  padding: 0.2667rem;
}