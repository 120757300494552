html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none;
}

body {
  font-family: "PingFangSC-Regular","Helvetica Neue","Helvetica","Arial",sans-serif;
  -webkit-user-select: none;
  margin: 0;
  padding: 0;
  font-size: 0.1867rem;
  color: #333;
  background: #fff;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body::-webkit-scrollbar {
  display: none;
}

#root {
  position: relative;
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
p,
dl,
dt,
dd,
ul,
ol,
li,
th,
td,
pre,
button,
input,
textarea {
  margin: 0;
  padding: 0;
}

a,
input,
select,
textarea {
  outline: none;
}

a {
  text-decoration: none;
}

img {
  border-style: none;
}

ol,
ul,
li {
  list-style: none;
}

.line-all {
  background-image: linear-gradient(180deg, #eaeaea 0, #eaeaea 50%, transparent 0),linear-gradient(90deg, transparent 50%, #eaeaea 0, #eaeaea),linear-gradient(180deg, transparent 50%, #eaeaea 0, #eaeaea),linear-gradient(90deg, #eaeaea 0, #eaeaea 50%, transparent 0);
  background-position: top,0,bottom,100%;
  background-size: 100% 0.0133rem,0.0133rem 100%,100% 0.0133rem,0.0133rem 100%;
}

.line-top {
  background-image: linear-gradient(180deg, #eaeaea 0, #eaeaea 50%, transparent 0);
  background-position: top;
  background-size: 100% 0.0133rem;
}

.line-right {
  background-image: linear-gradient(90deg, transparent 50%, #eaeaea 0, #eaeaea);
  background-position: 100%;
  background-size: 0.0133rem 100%;
}

.line-bottom {
  background-image: linear-gradient(180deg, transparent 50%, #eaeaea 0, #eaeaea);
  background-position: bottom;
  background-size: 100% 0.0133rem;
}

.line-left {
  background-image: linear-gradient(90deg, #eaeaea 0, #eaeaea 50%, transparent 0);
  background-position: 0;
  background-size: 0.0133rem 100%;
}

.line-topbottom {
  background-image: linear-gradient(180deg, #eaeaea 0, #eaeaea 50%, transparent 0),linear-gradient(180deg, transparent 50%, #eaeaea 0, #eaeaea);
  background-position: top,bottom;
  background-size: 100% 0.0133rem,100% 0.0133rem;
}

@media (-webkit-min-device-pixel-ratio: 2),(min-device-pixel-ratio: 2) {
  .line-all {
    background-image: linear-gradient(to bottom, #eaeaea 0, #eaeaea 50%, transparent 50%),linear-gradient(to right, transparent 50%, #eaeaea 50%, #eaeaea 100%),linear-gradient(to bottom, transparent 50%, #eaeaea 50%, #eaeaea 100%),linear-gradient(to right, #eaeaea 0, #eaeaea 50%, transparent 50%);
    background-repeat: no-repeat;
    background-position: top,left,bottom,right;
    background-size: 100% 0.0133rem,0.0133rem 100%,100% 0.0133rem,0.0133rem 100%;
    border: none;
  }

  .line-topbottom {
    background-image: linear-gradient(to bottom, #eaeaea 0, #eaeaea 50%, transparent 50%),linear-gradient(to bottom, transparent 50%, #eaeaea 50%, #eaeaea 100%);
    background-repeat: no-repeat;
    background-position: top,bottom;
    background-size: 100% 0.0133rem,100% 0.0133rem;
    border: none;
  }

  .line-right {
    background-image: linear-gradient(to right, transparent 50%, #697d91 50%, #697d91 100%);
    background-repeat: no-repeat;
    background-position: right;
    background-size: 0.0133rem 100%;
    border: none;
  }

  .line-left {
    background-image: linear-gradient(to right, #eaeaea 0, #eaeaea 50%, transparent 50%);
    background-repeat: no-repeat;
    background-position: left;
    background-size: 0.0133rem 100%;
    border: none;
  }

  .line-top {
    background-image: linear-gradient(to bottom, #eaeaea 0, #eaeaea 50%, transparent 50%);
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100% 0.0133rem;
    border: none;
  }

  .line-bottom {
    background-image: linear-gradient(to bottom, transparent 50%, #eaeaea 50%, #eaeaea 100%);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% 0.0133rem;
    border: none;
  }
}

@media (-webkit-min-device-pixel-ratio: 3),(min-device-pixel-ratio: 3) {
  .line-all {
    background-image: linear-gradient(to bottom, #eaeaea 0, #eaeaea 33%, transparent 33%),linear-gradient(to right, transparent 66%, #eaeaea 66%, #eaeaea 100%),linear-gradient(to bottom, transparent 66%, #eaeaea 66%, #eaeaea 100%),linear-gradient(to right, #eaeaea 0, #eaeaea 33%, transparent 33%);
    background-repeat: no-repeat;
    background-position: top,left,bottom,right;
    background-size: 100% 0.0133rem,0.0133rem 100%,100% 0.0133rem,0.0133rem 100%;
    border: none;
  }

  .line-topbottom {
    background-image: linear-gradient(to bottom, #eaeaea 0, #eaeaea 33%, transparent 33%),linear-gradient(to bottom, transparent 65%, #eaeaea 65%, #eaeaea 100%);
    background-repeat: no-repeat;
    background-position: top,bottom;
    background-size: 100% 0.0133rem,100% 0.0133rem;
    border: none;
  }

  .line-right {
    background-image: linear-gradient(to right, transparent 65%, #697d91 65%, #697d91 100%);
    background-repeat: no-repeat;
    background-position: right;
    background-size: 0.0133rem 100%;
    border: none;
  }

  .line-left {
    background-image: linear-gradient(to right, #eaeaea 33%, #eaeaea 33%, transparent 33%);
    background-repeat: no-repeat;
    background-position: left;
    background-size: 0.0133rem 100%;
    border: none;
  }

  .line-top {
    background-image: linear-gradient(to bottom, #eaeaea 0, #eaeaea 33%, transparent 33%);
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100% 0.0133rem;
    border: none;
  }

  .line-bottom {
    background-image: linear-gradient(to bottom, transparent 65%, #eaeaea 65%, #eaeaea 100%);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% 0.0133rem;
    border: none;
  }
}

.px-line,
.px-line-after,
.px-line-after-child,
.px-line-before,
.px-line-before-child {
  position: relative;
}

.px-line-after-child::after,
.px-line-after::after,
.px-line-before-child::before,
.px-line-before::before,
.px-line::after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 0.0133rem !important;
  content: "";
  -webkit-transform: scaleY(0.5);
  transform: scaleY(0.5);
  background-color: #eaeaea;
}

.px-line-after-child::after,
.px-line-before-child::before {
  left: 0.4rem;
}

.px-line-before-child::before,
.px-line-before::before {
  bottom: auto;
  top: 0;
}

.transition {
  transition: all .25s;
}

.hidden-text {
  word-break: break-all;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.fun-scroll-view .fun-loading .loading-content {
  background-color: rgba(0,0,0,0);
}

.border-radius-img {
  border-radius: 0.16rem;
  overflow: hidden;
}

.fun-toast {
  z-index: 10002;
}