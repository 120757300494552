.header[_header] {
  position: relative;
  text-align: center;
  background-color: #cbb486;
}

.fixed-header-container[_header] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 101;
}

.title[_header] {
  font-size: 0.48rem;
  color: #fff;
  font-family: HarmonyOSSansSC-Bold;
  font-weight: bolder;
}

.left[_header],
.right[_header] {
  position: absolute;
  top: 0;
  z-index: 2;
  text-align: center;
  white-space: nowrap;
}

.left[_header] {
  left: 0;
}

.right[_header] {
  right: 0;
  font-size: 0.3733rem;
  padding-right: 0.4rem;
}

.back[_header] {
  position: relative;
  color: #fff;
  font-size: 0.5333rem;
}

.back[_header] .fun-icon {
  line-height: inherit;
}

.back-i[_header] {
  display: inline-block;
  width: 0.4rem;
  height: 0.4rem;
  line-height: 0.4rem;
  overflow: hidden;
  text-align: center;
  font-size: 0.2133rem;
}

.apartment-header[_header] {
  background-color: #fafafa;
}