.simulation-experience-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.simulation-experience-container .bg-img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.simulation-experience-container .simulation-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
}

.simulation-experience-container .simulation-content .simulation-loading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
  color: #ffb773;
  font-size: 0.3467rem;
  font-weight: 600;
}

.simulation-experience-container .simulation-content .title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.4rem;
}

.simulation-experience-container .simulation-content .title-container .hongqi-img {
  width: 0.6667rem;
  height: 0.6667rem !important;
}

.simulation-experience-container .simulation-content .title-container .title {
  color: #ffd0a3;
  font-size: 0.4533rem;
  font-weight: 900;
}

.simulation-experience-container .simulation-content .swiper-container {
  height: 5.0667rem;
}

.simulation-experience-container .simulation-content .experience {
  flex: 1;
  padding: 0 0.5333rem;
  margin-right: auto;
}

.simulation-experience-container .simulation-content .grow-up {
  width: 6.9333rem;
  height: 1.3333rem;
}

.simulation-experience-container .simulation-content .summarize-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  overflow: scroll;
}

.simulation-experience-container .simulation-content .summarize {
  flex: 1;
  color: #ffb773;
  font-size: 0.3467rem;
  font-weight: 600;
  line-height: 0.8rem;
  padding: 0 0.9867rem;
  overflow: scroll;
}

.simulation-experience-container .simulation-content .simulator-again {
  width: 6.9333rem;
  height: 1.3333rem;
  margin-top: 0.24rem;
}

.simulation-experience-container .pop .pop-img-content {
  margin: 0.32rem 0.2rem 0 0.2rem;
}

.simulation-experience-container .pop .pop-content-container {
  margin: 0.48rem 0.8rem 0 0.8rem;
}

.simulation-experience-container .pop .pop-content-container .pop-content {
  color: #ffb773;
  font-size: 0.3467rem;
  font-weight: 600;
  line-height: 0.6133rem;
  letter-spacing: 0;
}

.simulation-experience-container .fun-dialog .fun-dialog-main {
  background-color: #b21910;
}

.simulation-experience-container .fun-dialog .fun-dialog-main .fun-dialog-action .fun-dialog-confirm {
  background-color: #b21910;
}