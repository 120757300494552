.count-down-time-text {
  width: 2.9467rem;
  height: 1.2rem;
  background-color: #cbb486;
  border-radius: 0.1867rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 0.4267rem;
  font-weight: 400;
  font-family: HarmonyOSSansSC-Regular;
}