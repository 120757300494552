.register-container {
  display: flex;
  flex-direction: column;
  margin: 0 0.4rem;
  padding-top: 0.8933rem;
  height: 100vh;
}

.register-container .register-content-container {
  display: flex;
  flex-direction: column;
}

.register-container .register-content-container .register-item-container,
.register-container .register-content-container .register-btn {
  margin-top: 0.8933rem;
}

.register-container .register-content-container .register-protocol-container {
  height: 0.6133rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.8933rem;
}

.register-container .register-content-container .register-protocol-container .protocol {
  color: #383838;
  font-size: 0.36rem;
  margin-left: 0.1067rem;
  font-family: HarmonyOSSansSC-Regular;
  font-weight: 400;
}

.register-container .register-content-container .register-protocol-container .protocol-link {
  color: #cbb486;
  font-size: 0.36rem;
  font-family: HarmonyOSSansSC-Regular;
  font-weight: 400;
}

.register-container .register-content-container .register-otp-container {
  position: relative;
}

.register-container .register-content-container .register-otp-container .register-otp-item-container {
  margin-top: 0.8933rem;
}

.register-container .register-content-container .register-otp-container .register-send-otp {
  position: absolute;
  top: 0.8933rem;
  right: 0;
  bottom: 0;
}