.login-otp-container {
  display: flex;
  flex-direction: column;
  margin: 0 0.4rem;
  padding-top: 0.8933rem;
  height: 100vh;
}

.login-otp-container .login-content-container {
  display: flex;
  flex-direction: column;
}

.login-otp-container .login-content-container .login-item-container,
.login-otp-container .login-content-container .login-btn {
  margin-top: 0.8933rem;
}

.login-otp-container .login-content-container .login-otp-inner {
  position: relative;
}

.login-otp-container .login-content-container .login-otp-inner .login-otp-item-container {
  margin-top: 0.8933rem;
}

.login-otp-container .login-content-container .login-otp-inner .login-send-otp {
  position: absolute;
  top: 0.8933rem;
  right: 0;
  bottom: 0;
}