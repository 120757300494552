.fun-button {
  display: inline-block;
  width: 100%;
  padding: 0;
  margin: 0;
  box-sizing: content-box;
  transition: background-color 200ms ease;
  overflow: hidden;
  vertical-align: middle;
  position: relative;
  border: 0.0133rem solid rgba(0,0,0,0);
}

.fun-button+.fun-button {
  margin-left: 0.2667rem;
}

.fun-button button {
  font-size: 0.3733rem;
  box-sizing: border-box;
}

.fun-button.default button {
  height: 1.0667rem;
}

.fun-button.small {
  width: 3.2rem;
}

.fun-button.small button {
  height: 0.8rem;
  font-size: 0.32rem;
  min-width: 1.2rem;
}

.fun-button.small2 {
  width: 2.0533rem;
}

.fun-button.small2 button {
  height: 0.7733rem;
  font-size: 0.4rem;
  min-width: 0.7733rem;
}

.fun-button.big button {
  height: 1.28rem;
  font-size: 0.4267rem;
}

.fun-button button {
  display: block;
  background-color: rgba(0,0,0,0);
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
}

.fun-button.white,
.fun-button.white2 {
  background-color: #fff;
  border-color: #cbb486;
  border-radius: 1.3333rem;
}

.fun-button.white button,
.fun-button.white2 button {
  color: #cbb486;
}

.fun-button.white2 button {
  color: #949aa7;
}

.fun-button.primary {
  background-color: #cbb486;
  border-radius: 0.8rem;
}

.fun-button.primary button {
  color: #fff;
}

.fun-button.primary.disabled {
  background-color: #edd180 !important;
}

.fun-button.primary.disabled button {
  color: #d9bd68 !important;
}

.fun-button.primary2 {
  background-color: rgba(0,0,0,0);
  border-radius: 0.8rem;
  border-color: #edd180;
}

.fun-button.primary2 button {
  color: #795600;
}

.fun-button.primary2.disabled {
  border-color: #66604c !important;
  background-color: rgba(0,0,0,0) !important;
}

.fun-button.primary2.disabled button {
  color: #786e4c !important;
}

.fun-button.disabled {
  border-color: #c6cbd7;
  background-color: #c6cbd7 !important;
}

.fun-button.disabled .inner-btn {
  color: #798190 !important;
}

.fun-button.primary2 {
  border-color: #edd180;
  background-color: rgba(0,0,0,0);
  border-radius: 0.8rem;
}

.fun-button.primary2 button {
  color: #edd180;
}

.fun-button.primary2.disabled {
  border-color: #66604c !important;
  background-color: rgba(0,0,0,0) !important;
}

.fun-button.primary2.disabled button {
  color: #786e4c !important;
}

.fun-button.primary.hover-btn {
  background-color: #cbb486;
}

.fun-button.primary.hover-btn button {
  color: #fff;
}

.fun-button.primary.disabled {
  border-color: #ffe9aa !important;
  background-color: #ffe9aa !important;
}

.fun-button.primary.disabled button {
  color: #d9bd6b;
}