.fun-dialog {
  position: fixed;
  z-index: 10000010;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity 250ms cubic-bezier(0.8, 0, 0.2, 1);
  opacity: 1;
  user-select: none;
}

.fun-dialog .fun-dialog-back {
  background-color: rgba(0,0,0,.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.fun-dialog .fun-dialog-main {
  position: absolute;
  box-sizing: content-box;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: #fff;
  z-index: 2;
  min-width: 8.9333rem;
  max-width: 70%;
  max-height: 70%;
  border-radius: 0.2133rem;
  overflow: hidden;
}

.fun-dialog .fun-dialog-main .line-bottom {
  height: 0.0133rem;
  background-color: #efefef;
  overflow: hidden;
}

.fun-dialog .fun-dialog-main .fun-dialog-action {
  height: 1.6rem;
  line-height: 1.6rem;
  font-size: 0.5333rem;
  color: #949aa7;
  text-align: center;
  user-select: none;
}

.fun-dialog .fun-dialog-main .fun-dialog-action .fun-dialog-cancel {
  display: inline-block;
  width: 50%;
  background-color: #fff;
}

.fun-dialog .fun-dialog-main .fun-dialog-action .line-right {
  background-image: linear-gradient(to right, transparent 65%, #efefef 65%, #efefef 100%);
}

.fun-dialog .fun-dialog-main .fun-dialog-action .fun-dialog-confirm {
  display: inline-block;
  color: #cbb486;
  background-color: #fff;
}

.fun-dialog .fun-dialog-main .fun-dialog-action .fun-dialog-cancel.active,
.fun-dialog .fun-dialog-main .fun-dialog-action .fun-dialog-confirm.active {
  background-color: #eee;
}

.fun-dialog .fun-dialog-main .header-part {
  color: #949aa7;
  padding: 0.2667rem;
  position: relative;
  align-items: center;
  line-height: 0.6667rem;
  box-sizing: content-box;
}

.fun-dialog .fun-dialog-main .header-part .icon-con {
  width: 0.8rem;
  text-align: center;
}

.fun-dialog .fun-dialog-main .header-part .fun-icon {
  font-size: 0.5333rem;
  line-height: inherit;
}

.fun-dialog .fun-dialog-main .header-part .fun-dialog-title {
  width: 100%;
  text-align: center;
  font-size: 0.5867rem;
  color: #333;
  margin-top: 0.6667rem;
}

.fun-dialog .fun-dialog-main .fun-dialog-wrapper {
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.3733rem;
  padding-bottom: 0.4667rem;
}

.fun-dialog .fun-dialog-main .fun-dialog-wrapper .fun-dialog-content {
  font-size: 0.4267rem;
  color: #383838;
  letter-spacing: .0.7467rem;
  text-align: center;
  line-height: 0.6667rem;
}

.fun-dialog-enter {
  opacity: 0;
}

.fun-dialog-enter .fun-dialog-main {
  transform: translateX(-50%) translateY(-50%) scale(0.2, 0.2);
}

.fun-dialog-enter-active {
  opacity: 1;
}

.fun-dialog-enter-active .fun-dialog-main {
  transform: translateX(-50%) translateY(-50%) scale(1, 1);
  transition: all 300ms ease-out;
}

.fun-dialog-exit {
  opacity: 1;
}

.fun-dialog-exit .fun-dialog-main {
  transform: translateX(-50%) translateY(-50%) scale(1, 1);
}

.fun-dialog-exit-active {
  opacity: 0;
}

.fun-dialog-exit-active .fun-dialog-main {
  transform: translateX(-50%) translateY(-50%) scale(0.2, 0.2);
  transition: all 300ms ease-in;
}

.fun-dialog-exit-done {
  opacity: .1;
}

.fun-dialog-exit-done .fun-dialog-main {
  transform: translateX(-50%) translateY(-50%) scale(0.1, 0.1);
}